.hero {
  /* Sizing */
  width: 100vw;
  height: 100vh;

  /* flexbox */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Background styles */
  /* background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('./resources/images/warehouse.jpg'); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
